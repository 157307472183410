<template>
  <div>
    <article>
      <h3 class="center">{{ appName }}App第三方信息共享清单</h3>
      <section>
        <p>
          为保障{{
            appName
          }}App的稳定运行以及实现包括但不限于第三方账号登录、消息推送、运营统计、第三方分享等的相关功能，我们会接入由合作方提供的软件开发包（SDK）、应用程序接口（API）、应用程序插件等代码或通过其他合作方式，以实现前述目的。我们会对获取信息的合作方的代码进行严格的安全监测，以保护数据安全。请注意，合作方的代码可能因为其版本升级、策略调整等原因导致数据处理类型存在一定变化，请以其公示的官方说明为准。
        </p>
      </section>
      <section>
        <div class="bold">为使您更清晰的了解我们有关个人信息共享情况，制定下述清单</div>
        <div class="bold">1、第三方SDK或类似应用程序列表</div>
        <div class="bold">2、合作方第三方信息共享清单</div>
      </section>
      <section>
        <div class="bold">一、第三方SDK或类似应用程序列表</div>
        <p class="bold">
          注意：为了持续向您提供更多优质服务、持续优化我们提供服务的稳定性、服务质量或者升级相关功能，我们可能会调整我们接入的第三方SDK或类似应用程序并更新接入此目录。我们会严格遵守相关法律法规与监管要求，在接入SDK前评估第三方SDK或类似应用程序收集个人信息的合法性、正当性、必要性，以保护个人信息安全。
        </p>
      </section>
      <section>
        <ul>
          <li v-for="item in tableData" style="margin: 12px auto">
            <div>
              <span class="bold">SDK 名称:</span> <span>{{ item['ProductName'] }}</span>
            </div>
            <div>
              <span class="bold">第三方公司名称:</span> <span>{{ item['CompanyName'] }}</span>
            </div>
            <div>
              <span class="bold">用途/使用场景:</span> <span>{{ item['Uses'] }}</span>
            </div>
            <div>
              <span class="bold">收集的信息类型:</span> <span>{{ item['Type'] }}</span>
            </div>
            <div>
              <span class="bold">第三方个人信息保护政策链接或官网：</span>
              <ul class="align-start" v-if="Array.isArray(item['Links'])">
                <li v-for="link in item['Links']">
                  <a :href="link">{{ link }}</a>
                </li>
              </ul>
              <ul class="align-start" v-else>
                <li>
                  <a :href="item['Links']">{{ item['Links'] }}</a>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </section>
      <section>
        <div class="bold">二、合作方第三方信息共享清单</div>
      </section>
      <section>
        <ul>
          <li v-for="item in tableData1" style="margin: 12px auto">
            <div>
              <span class="bold">数据接受方:</span> <span>{{ item['Receive'] }}</span>
            </div>
            <div>
              <span class="bold">联系方式:</span> <span>{{ item['Contact'] }}</span>
            </div>
            <div>
              <span class="bold">处理方式:</span> <span>{{ item['Treatment'] }}</span>
            </div>
            <div>
              <span class="bold">用途:</span> <span>{{ item['Uses'] }}</span>
            </div>
            <div>
              <span class="bold">共享个人信息类型:</span>
              <span>{{ item['Type'] }}</span>
            </div>
          </li>
        </ul>
      </section>
    </article>
  </div>
</template>

<script>
  export default {
    name: 'UserReleaseCommitment',
    components: {},
    props: {},
    data() {
      return {
        tableData: [
          {
            ProductName: '推啊SDK',
            CompanyName: '杭州推啊网络科技有限公司、霍尔果斯推啊网络科技有限公司',
            Uses: '互动广告合作',
            Type: '设备信息（设备厂商 设备型号 设备内存 操作系统版本 IMSI IMEI AndroidID OAID）、传感器、WiFi路由器MAC地址、设备的MAC地址、wifi state、重力传感器',
            Links: 'https://www.tuia.cn/#section-2',
          },
          {
            ProductName: 'sigmob',
            CompanyName: '北京创智汇聚科技有限公司',
            Uses: 'SDK广告合作',
            Type: '设备信息-IMEI、设备信息-MAC、Android ID、OpenUDID、GUID、IP、DEVICEID、传感器、WiFi路由器MAC地址、wifi state',
            Links: ['https://www.sigmob.com/#', 'http://sigmob.com'],
          },
          {
            ProductName: '小满',
            CompanyName: '杭州小满信息科技有限公司',
            Uses: 'SaaS营销工具',
            Type: '设备信息（设备厂商 设备型号 设备内存 操作系统版本 IMSI IMEI AndroidID OAID）、用户ID',
            Links: 'https://www.hixiaoman.com/#/about',
          },
          {
            ProductName: '变现猫',
            CompanyName: '北京众成聚美广告有限公司',
            Uses: '互动广告&活动场景',
            Type: '设备信息-IMEI、设备信息-MAC、Android ID、OpenUDID、GUID、IP、DEVICEID',
            Links: 'http://ssp.bianxianmao.com/#/aboutBxm/privacy',
          },
          {
            ProductName: '快手',
            CompanyName: '北京快手广告有限公司',
            Uses: '用于广告推送',
            Type: 'AndroidID、MAC地址、已安装APP信息、监视传感器、查看WLAN链接、设置壁纸、SD卡_创建目录、SD卡_查询文件列表、SD卡_删除文件、SD卡_创建文件、热修复_资源、SD卡_创建文件_读写文件_删除文件、WiFi路由器MAC地址、wifi state、重力传感器',
            Links: 'https://www.kuaishou.com/#http://kuaishou.com',
          },
          {
            ProductName: '腾讯优量汇',
            CompanyName: '腾讯科技（深圳）有限公司',
            Uses: '用于广告推送',
            Type: '当前运行应用进程、设备运营商、已安装APP信息、传感器信息、WiFi路由器MAC地址、设备的MAC地址、wifi state、加速度传感器、重力传感器',
            Links: 'https://developers.adnet.qq.com/doc/android/access_doc',
          },
          {
            ProductName: '穿山甲',
            CompanyName: '湖北今日头条科技有限公司',
            Uses: '用于广告推送',
            Type: '设备信息（设备厂商 设备型号 设备内存 操作系统版本 IMSI IMEI AndroidID OAID）、传感器、WiFi路由器MAC地址、设备的MAC地址、wifi state、SD卡_创建目录、SD卡_查询文件列表获取IP地址、SD卡_创建文件_读写文件_删除文件、获取IP地址、获取正在运行的进程、监视多媒体文件、获取WIFI的IP地址、加速度传感器、获取运营商信息_NETWORK、重力传感器',
            Links: 'https://www.pangle.cn/terms/novelsdk-compliance-guide',
          },
          {
            ProductName: '穿山甲（内容）',
            CompanyName: '北京有竹居网络技术有限公司',
            Uses: '联盟内容合作',
            Type: '设备信息（设备厂商 设备型号 设备内存 操作系统版本 IMSI IMEI AndroidID OAID）、SD卡_创建目录、SD卡_查询文件列表、SD卡_创建文件、设备标识符（IMEI、IDFA、Android ID、IMSI、OAID 、MAC 地址）传感器信息、WiFi路由器MAC地址、获取IP地址、获取正在运行的进程',
            Links: 'https://www.pangle.cn/terms/novelsdk-compliance-guide',
          },
          {
            ProductName: '百度网盟广告',
            CompanyName: '百度时代网络技术（北京）有限公司',
            Uses: '用于广告推送',
            Type: '当前运行应用进程、已安装APP信息、读取设备IP、设备运营商、已安装APP信息、系统属性、监视多媒体文件、SD卡_创建目录、SD卡_查询文件列表、SD卡_创建文件、设备标识符（IMEI、IDFA、Android ID、IMSI、OAID 、MAC 地址）传感器信息、WiFi路由器MAC地址、重力传感器',
            Links: 'https://app.baidu.com/newapp/index/',
          },
          {
            ProductName: '懒人',
            CompanyName: '北京懒人科技有限公司',
            Uses: '广告聚合平台合作',
            Type: '【仅iOS端】设备标识符（IMEI、IDFA、Android ID、IMSI、OAID 、MAC 地址）',
            Links: 'https://www.wasair.com',
          },
          {
            ProductName: 'Umeng（友盟）SDK+',
            CompanyName: '友盟同欣（北京）科技有限公司、北京锐讯灵通科技有限公司',
            Uses: '数据统计分析、推送信息',
            Type: '设备信息-IMEI、设备信息-MAC、Android ID、OpenUDID、GUID、IP、DEVICEID',
            Links: 'https://www.umeng.com/page/policy',
          },
          {
            ProductName: '小米PUSH',
            CompanyName: '小米科技有限责任公司',
            Uses: '推送消息',
            Type: '设备信息-IMEI、设备信息-MAC、Android ID、OpenUDID、GUID、IP、DEVICEID',
            Links: 'https://privacy.mi.com/all/zh_CN/',
          },
          {
            ProductName: 'OPPO PUSH',
            CompanyName: '广东欢太科技有限公司',
            Uses: '推送消息',
            Type: '已安装APP信息、设备运营商',
            Links: 'https://open.oppomobile.com/wiki/doc#id=10288',
          },
          {
            ProductName: '华为PUSH',
            CompanyName: '华为技术有限公司',
            Uses: '推送消息',
            Type: '设备信息-IMEI、设备信息-MAC、Android ID、OpenUDID、GUID、IP、DEVICEID',
            Links:
              'https://developer.huawei.com/consumer/cn/doc/development/HMSCore-Guides/privacy-statement-0000001050042021',
          },
          {
            ProductName: 'VIVO PUSH',
            CompanyName: '广东天宸网络科技有限公司',
            Uses: '推送消息',
            Type: '设备信息-IMEI、设备信息-MAC、Android ID、OpenUDID、GUID、IP、DEVICEID',
            Links: 'https://dev.vivo.com.cn/documentCenter/doc/366',
          },
          {
            ProductName: '腾讯Bugly',
            CompanyName: '深圳市腾讯计算机系统有限公司',
            Uses: '数据分析',
            Type: '系统属性、已安装APP信息、SD卡_创建目录、查看网络状态',
            Links: 'https://bugly.qq.com/',
          },
          {
            ProductName: '腾讯Turingfd',
            CompanyName: '腾讯云计算（北京）有限责任公司',
            Uses: '人脸识别',
            Type: '已安装APP信息',
            Links: 'https://cloud.tencent.com/document/product/1007/57620',
          },
          {
            ProductName: '移动安全联盟',
            CompanyName: '电信终端产业协会移动安全工作委员会',
            Uses: '设备识别',
            Type: '已安装APP信息',
            Links: 'http://msa-alliance.cn/',
          },
          {
            ProductName: '火山点播SDK',
            CompanyName: '北京火山引擎科技有限公司',
            Uses: '内容播放',
            Type: '设备标识符（ IMSI、IMEI、MAC地址、AndroidID、SSID、BSSID、OAID、UDID）、获取正在运行的进程',
            Links: 'https://www.volcengine.com/docs/4/65789',
          },
          {
            ProductName: '阿里云OSS',
            CompanyName: '阿里云计算有限公司',
            Uses: '存储',
            Type: '设备名称、设备标识符（IMEI AndroidID OAID）、设备激活时间、硬件型号、操作系统版本、应用程序版本、软件识别码、设备及应用设置（如地区/语言/时区/字号）、用户内容数据',
            Links:
              'http://terms.aliyun.com/legal-agreement/terms/suit_bu1_ali_cloud/suit_bu1_ali_cloud201902141711_54837.html?spm=a2c4g.11186623.0.0.3fc854ccQivGc0',
          },
          {
            ProductName: '阿里云',
            CompanyName: '阿里云计算有限公司',
            Uses: '存储',
            Type: '设备名称、设备标识符（IMEI AndroidID OAID）、设备激活时间、硬件型号、操作系统版本、应用程序版本、软件识别码、设备及应用设置（如地区/语言/时区/字号）、用户内容数据',
            Links:
              'http://terms.aliyun.com/legal-agreement/terms/suit_bu1_ali_cloud/suit_bu1_ali_cloud201902141711_54837.html?spm=5176.24700566.J_9220772140.78.483457ffodXUSQ',
          },
          {
            ProductName: '腾讯云',
            CompanyName: '腾讯科技（深圳）有限公司',
            Uses: '一键免密注册、登录',
            Type: '设备信息-IMEI、设备信息-MAC、Android ID、OpenUDID、GUID、IP、DEVICEID、用户ID',
            Links:
              'https://cloud.tencent.com/?fromSource=gwzcw.2212127.2212127.2212127&utm_medium=cpd&utm_id=gwzcw.2212127.2212127.2212127',
          },
          {
            ProductName: '网易七鱼客服系统',
            CompanyName: '杭州网易质云科技有限公司',
            Uses: '客服系统',
            Type: '设备信息（设备厂商 设备型号 设备内存 操作系统版本 IMSI IMEI AndroidID OAID）、用户资料、用户ID',
            Links: 'https://reg.163.com/agreement_mobile_ysbh_wap.shtml?v=20171127',
          },
          {
            ProductName: '华为',
            CompanyName: '华为终端有限公司',
            Uses: '音频编辑',
            Type: '用户内容数据',
            Links: 'https://developer.huawei.com/consumer/cn/hms',
          },
          {
            ProductName: '个推',
            CompanyName: '每日互动股份有限公司',
            Uses: '推送消息',
            Type: 'AndroidID、读取ICCID、设备信息-IMEI、设备信息-IMSI、MAC地址 、WIFI的BSSID、WIFI的SSID、系统属性、设备序列号、已安装APP信息、SD卡_创建目录、SD卡_查询文件列表、SD卡_创建文件、获取WIFI的信号强度',
            Links: 'https://docs.getui.com/privacy/?from_wecom=1',
          },
          {
            ProductName: '趣盟qumeng',
            CompanyName: '上海基分文化传播有限公司',
            Uses: '联盟广告合作',
            Type: '设备信息（设备厂商 设备型号 设备内存 操作系统版本 IMSI IMEI AndroidID OAID）、SD卡_创建目录、获取正在运行的进程、获取网络接口MAC地址、SD卡_查询文件列表、注册传感器监听器_TYPE_ACCELEROMETER、获取IP地址、写入系统剪切板、SD卡_创建文件_读写文件_删除文件、获取已安装的应用列表、重力传感器',
            Links: 'https://cdn.aiclk.com/nsdk/res/open/qu-privacy-agreement.html',
          },
          {
            ProductName: '拼多多',
            CompanyName: '上海寻梦信息技术有限公司',
            Uses: '联盟广告',
            Type: '设备信息（设备厂商 设备型号 设备内存 操作系统版本 IMSI IMEI AndroidID OAID）、传感器、WiFi路由器MAC地址、设备的MAC地址、wifi state',
            Links: 'https://www.pinduoduo.com/pdd_privacy_policy.pdf',
          },
          {
            ProductName: '腾讯tbs',
            CompanyName: '腾讯科技（深圳）有限公司',
            Uses: '浏览服务',
            Type: '设备信息-IMEI、设备信息-MAC、Android ID、OpenUDID、GUID、IP、DEVICEID、SD卡_创建目录、读SDCard',
            Links: 'https://x5.tencent.com/docs/tbsapi.html',
          },
          {
            ProductName: 'vivo广告联盟',
            CompanyName: '广东天宸网络科技有限公司 维沃移动通信有限公司',
            Uses: '联盟广告合作',
            Type: '设备名称、设备标识符（IMEI AndroidID OAID）、设备激活时间、硬件型号、操作系统版本、应用程序版本、软件识别码、设备及应用设置（如地区/语言/时区/字号）、传感器、WiFi路由器MAC地址、设备的MAC地址、wifi state、重力传感器',
            Links: 'https://www.vivo.com.cn/about-vivo/privacy-policy',
          },
          {
            ProductName: 'oppo广告联盟',
            CompanyName: '广东欢太科技有限公司',
            Uses: '联盟广告合作',
            Type: '设备信息-IMEI、设备信息-MAC、Android ID、OpenUDID、GUID、IP、DEVICEID、传感器、WiFi路由器MAC地址、设备的MAC地址、wifi state',
            Links: 'https://u.oppomobile.com/main/privacy.html',
          },
          {
            ProductName: '阿里云视频上传功能',
            CompanyName: '阿里云计算有限公司',
            Uses: '歌单上传封面、上传头像、上传图片/视频、发布歌曲/视频',
            Type: '外部存储读写、访问网络、查看网络状态、查看修改WIFI状态',
            Links: 'https://help.aliyun.com/document_detail/32041.html',
          },
          {
            ProductName: '华为开放id接口',
            CompanyName: '华为技术有限公司',
            Uses: '消息推送',
            Type: '唯一设备识别码',
            Links: 'https://consumer.huawei.com/cn/privacy/privacy-policy/',
          },
          {
            ProductName: '华为HMS核心功能',
            CompanyName: '华为技术有限公司',
            Uses: '消息推送',
            Type: '唯一设备识别码',
            Links: 'https://consumer.huawei.com/cn/privacy/privacy-policy/',
          },
          {
            ProductName: '设备标识生成库(支付宝)',
            CompanyName: '支付宝（中国）网络技术有限公司',
            Uses: '会员支付',
            Type: '唯一设备识别码',
            Links: 'https://render.alipay.com/p/yuyan/180020010001196791/preview.html?agreementId=AG00000132',
          },
          {
            ProductName: '移动授权登录',
            CompanyName: '中移互联网有限公司',
            Uses: '绑定账号/一键登录',
            Type: '设备运营商、设备信息-IMSI、SIM卡信息（ICCID）',
            Links: ['https://cmic.chinamobile.com/', 'https://wap.cmpassport.com/resources/html/contract.html'],
          },
          {
            ProductName: '支付宝SDK、支付宝安全',
            CompanyName: '支付宝（中国）网络技术有限公司',
            Uses: '会员支付',
            Type: '设备标识符（imsi、imei、MAC地址、AndroidID、SSID、BSSID、OAID、UDID）、通讯录权限、剪切板信息、应用安装列表、日历信息、位置信息、IP或网关地址、摄像头或麦克风权限、读取外部存储目录、读取通话记录权限、拨号权限、WI-FI信息、设备登录的账号信息、短信权限',
            Links: [
              'https://b.alipay.com/index2.htm',
              'https://render.alipay.com/p/yuyan/180020010001196791/preview.html?agreementId=AG00000132',
            ],
          },
          {
            ProductName: '中国电信账号认证',
            CompanyName: '中国电信集团有限公司',
            Uses: '绑定账号/一键登录',
            Type: '设备信息（IMSI）、SIM卡信息（ICCID）',
            Links: 'https://e.189.cn/sdk/agreement/content.do?type=main&appKey=&hidetop=true&returnUrl=',
          },
          {
            ProductName: 'Umeng社会化分享',
            CompanyName: '北京锐讯灵通科技有限公司',
            Uses: '分享歌曲到三方应用（微信，QQ）',
            Type: '设备信息-IMEI,设备信息-IMSI,设备信息-MAC,用户位置信息、SD卡_查询文件列表、获取OAID_UMENG',
            Links: 'https://developer.umeng.com/docs/128606/cate/128606',
          },
          {
            ProductName: '今日头条激励广告',
            CompanyName: '字节跳动有限公司',
            Uses: '请求穿山甲联盟广告',
            Type: '设备信息-IMEI、设备信息-IMSI、设备信息-MAC、软件安装列表（正在运行的程序）、SD卡_创建目录、SD卡_查询文件列表、热修复_资源、传感器、WiFi路由器MAC地址、wifi state、获取IP地址、获取上次定位的位置、注册定位更新事件通知、加速度传感器、重力传感器',
            Links: 'https://www.csjplatform.com/',
          },
          {
            ProductName: 'QQ分享',
            CompanyName: '深圳市腾讯计算机系统有限公司',
            Uses: '分享到QQ',
            Type: '设备信息-IMEI、设备信息-IMSI、设备信息-MAC、用户位置信息、SD卡_创建目录、SD卡_查询文件列表',
            Links: 'https://developer.umeng.com/docs/128606/cate/128606',
          },
          {
            ProductName: '卓信ID',
            CompanyName: '中国信息通信研究院',
            Uses: '推送',
            Type: '设备信息-IMEI、设备信息-IMSI、设备信息-MAC',
            Links: 'http://www.caict.ac.cn/',
          },
          {
            ProductName: '电信一键登录',
            CompanyName: '世纪龙信息网络有限责任公司',
            Uses: '绑定账号/一键登录',
            Type: '设备信息-IMEI、设备信息-IMSI、设备信息-MAC、手机号',
            Links: 'https://id.dlife.cn/',
          },
          {
            ProductName: '微信OpenSDK Android',
            CompanyName: '深圳市腾讯计算机系统有限公司',
            Uses: '会员购买',
            Type: '用户资料,设备信息-IMEI、设备信息-IMSI、设备信息-MAC、SD卡_创建目录、SD卡_查询文件列表、读SDCard、SD卡_查询文件列表',
            Links: 'https://open.weixin.qq.com/',
          },
          {
            ProductName: '中国移动认证',
            CompanyName: '中国移动通信集团有限公司',
            Uses: '绑定账号/一键登录',
            Type: '设备信息-IMEI、设备信息-IMSI、设备信息-MAC、手机号',
            Links: 'https://cloud.tencent.com/product',
          },
          {
            ProductName: '七巧板通用',
            CompanyName: '深圳市腾讯计算机系统有限公司',
            Uses: '请求腾讯广告联盟',
            Type: '设备信息-IMEI、设备信息-IMSI、设备信息-MAC、SD卡_创建目录、SD卡_查询文件列表、SD卡_创建文件、注册传感器监听器_TYPE_ACCELEROMETER、获取已安装的应用列表、 SD卡_删除文件注册传感器监听器_TYPE_MAGNETIC_FIELD、注册传感器监听器_TYPE_GYROSCOPE、重力传感器',
            Links: 'http://developers.adnet.qq.com/doc/android/tangram_doc',
          },
          {
            ProductName: 'QQ互联',
            CompanyName: '深圳市腾讯计算机系统有限公司',
            Uses: '绑定账号/一键登录',
            Type: '设备信息-IMEI、设备信息-IMSI、设备信息-MAC',
            Links: 'https://q.qq.com/#/',
          },
          {
            ProductName: '微信OpenSDK',
            CompanyName: '深圳市腾讯计算机系统有限公司',
            Uses: '绑定账号/一键登录',
            Type: '设备信息-IMEI、设备信息-IMSI、设备信息-MAC',
            Links: 'https://open.weixin.qq.com/',
          },
          {
            ProductName: '阿里HttpDns',
            CompanyName: '阿里巴巴（中国）网络技术有限公司',
            Uses: 'dns加速',
            Type: '设备信息、Android系统设备标识、运营商信息、网络信息',
            Links:
              'https://terms.aliyun.com/legal-agreement/terms/suit_bu1_ali_cloud/suit_bu1_ali_cloud202112071754_83380.html?spm=a2c4g.11186623.0.0.3acc30e5sbczrx',
          },
          {
            ProductName: '友盟组件化基础库',
            CompanyName: '北京锐讯灵通科技有限公司',
            Uses: 'push基础组件',
            Type: '设备信息-IMEI、设备信息-MAC、Android ID、OpenUDID、GUID、SIM 卡、IMSI、IP、已安装APP信息、DEVICEID、设备序列号、设备运营商、SD卡_查询文件列表、获取OAID_UMENG',
            Links: 'https://www.umeng.com/page/policy',
          },
          {
            ProductName: '友盟智能超链',
            CompanyName: '北京锐讯灵通科技有限公司',
            Uses: '投放追踪',
            Type: '设备信息-IMEI、设备信息-MAC、Android ID、OpenUDID、GUID、SIM 卡、IMSI、IP',
            Links: 'https://www.umeng.com/page/policy',
          },
          {
            ProductName: '友盟应用性能监测SDK',
            CompanyName: '北京锐讯灵通科技有限公司',
            Uses: 'app性能监控',
            Type: '设备信息-IMEI、设备信息-MAC、Android ID、OpenUDID、GUID、SIM 卡、IMSI、IP',
            Links: 'https://www.umeng.com/page/policy',
          },
          {
            ProductName: '增长营销套件Applog',
            CompanyName: '北京火山引擎科技有限公司',
            Uses: '播放器性能监控',
            Type: 'Android ID、设备信息-IMEI、',
            Links: 'https://www.volcengine.com/docs/4/108995',
          },
          {
            ProductName: 'Inno',
            CompanyName: '上海泰蕴投资管理有限公司',
            Uses: '广告投放、反作弊',
            Type: '位置信息、应用列表、设备标识符（IMEI、IDFA、Android ID、IMSI、OAID 、MAC 地址）、WIFI的BSSID、WIFI的SSID、读取传感器列表、设备序列号、设备运营商、监视传感器、重力传感器',
            Links: 'https://quda.innotechx.com/pub/prd/ymQz.html',
          },
          {
            ProductName: '百度统计SDK',
            CompanyName: '百度时代网络技术（北京）有限公司',
            Uses: '统计分析服务',
            Type: '设备标识符（ IMSI、IMEI、MAC地址、AndroidID、SSID、BSSID、OAID、UDID）、获取正在运行的进程',
            Links: 'https://tongji.baidu.com/web/help/article?id=330&type=0',
          },
          {
            ProductName: '岳鹰全景监控SDK',
            CompanyName: '广州市动景计算机科技有限公司',
            Uses: '崩溃日志',
            Type: '设备信息、崩溃日志',
            Links: 'https://yueying-docs.effirst.com/app-start.html',
          },
          {
            ProductName: '联想统一认证SDK',
            CompanyName: '联想控股股份有限公司',
            Uses: '广告场景',
            Type: '设备标识符（ IMSI、IMEI、MAC地址、AndroidID、SSID、BSSID、OAID、UDID）',
            Links: 'https://open.lenovomm.com/sdk/passport/',
          },
          {
            ProductName: 'Android Support Library Compat',
            CompanyName: 'Google',
            Uses: '复制信息',
            Type: '剪贴版',
            Links: 'https://developer.android.com/topic/libraries/support-library/packages',
          },
          {
            ProductName: '哔哩哔哩多媒体SDK',
            CompanyName: '哔哩哔哩',
            Uses: '本地歌曲列表',
            Type: '媒体库',
            Links: 'https://github.com/bilibili/boxing',
          },
          {
            ProductName: 'Chromium',
            CompanyName: 'Google',
            Uses: '浏览网页',
            Type: 'SSID、获取正在运行的进程',
            Links: 'https://www.chromium.org/chromium-projects/',
          },
          {
            ProductName: 'Okhttp3',
            CompanyName: 'square',
            Uses: '网络库',
            Type: '网络请求数据',
            Links: 'https://square.github.io/okhttp/',
          },
          {
            ProductName: 'Okio',
            CompanyName: 'square',
            Uses: '序列化',
            Type: '网络请求数据、 SD卡_删除文件、SD卡_创建文件_读写文件_删除文件',
            Links: 'https://square.github.io/okio/',
          },
          {
            ProductName: 'Sanselan',
            CompanyName: 'apache',
            Uses: '图片取色',
            Type: '视频封面颜色',
            Links: 'https://github.com/apache/sanselan',
          },
          {
            ProductName: '乐播投屏',
            CompanyName: '深圳乐播科技有限公司',
            Uses: '音视频/图像处理、音乐详情页投屏到电视',
            Type: '设备信息（设备厂商 设备型号 设备内存 操作系统版本 IMSI IMEI AndroidID OAID）wifi状态 WIFI的BSSID、WIFI的SSID',
            Links: 'https://www.lebo.cn/SdkCooperation.jsp',
          },
          {
            ProductName: '抖音直播SDK',
            CompanyName: '北京微播视界科技有限公司',
            Uses: '直播',
            Type: '设备标识符（ IMSI、IMEI、MAC地址、AndroidID、SSID、BSSID、OAID、UDID）',
            Links: 'https://developer.open-douyin.com/docs/resource/zh-CN/dop/ability/douyin-live-sdk/solution',
          },
          {
            ProductName: '谷歌Gson',
            CompanyName: '谷歌公司',
            Uses: '网络数据流转化为实体对象',
            Type: '无',
            Links: 'https://github.com/google/gson',
          },
          {
            ProductName: 'tgpalite',
            CompanyName: '深圳市腾讯计算机系统有限公司',
            Uses: '基础功能类',
            Type: '设备厂商 设备型号 设备内存 操作系统版本 IMSI IMEI AndroidID OAID）传感器信息',
            Links: 'https://yky.qq.com/doc/agreement/use-agreement',
          },
          {
            ProductName: '广告SDK-内部full版、广告SDK-外部lite版',
            CompanyName: '华为软件技术有限公司',
            Uses: '广告类',
            Type: '设备名称、设备标识符（IMEI AndroidID OAID）、设备激活时间、硬件型号、操作系统版本、应用程序版本、软件识别码、设备及应用设置（如地区/语言/时区/字号）、传感器、WiFi路由器MAC地址',
            Links:
              'https://developer.huawei.com/consumer/cn/doc/development/HMSCore-Library/android-sdk-download-0000001050066905',
          },
          {
            ProductName: 'OPPO账号',
            CompanyName: '广东欢太科技有限公司',
            Uses: '广告/推广',
            Type: '设备信息-IMEI、设备信息-MAC、Android ID、OpenUDID、GUID、IP、DEVICEID',
            Links: 'https://open.oppomobile.com/',
          },
          {
            ProductName: '字节移动安全SDK',
            CompanyName: '北京字节跳动科技有限公司',
            Uses: '安全/加固',
            Type: '设备信息（设备厂商 设备型号 设备内存 操作系统版本 IMSI IMEI AndroidID OAID）',
            Links: 'https://www.volcengine.com/',
          },
          {
            ProductName: 'AliFastJson',
            CompanyName: '阿里巴巴（中国）网络技术有限公司',
            Uses: '其他',
            Type: '无',
            Links: 'https://github.com/alibaba/fastjson',
          },
          {
            ProductName: 'Google移动服务',
            CompanyName: 'Google Inc.',
            Uses: 'android基础框架',
            Type: '无',
            Links: 'https://www.android.com/',
          },
          {
            ProductName: 'Google官方设计库',
            CompanyName: 'Google Inc.',
            Uses: 'android基础框架',
            Type: '无',
            Links: 'https://www.android.com/',
          },
          {
            ProductName: 'AndroidX库',
            CompanyName: 'Google Inc.',
            Uses: 'android基础框架',
            Type: '无',
            Links: 'https://www.android.com/',
          },
          {
            ProductName: '倍孜',
            CompanyName: '上海倍孜网络技术有限公司',
            Uses: '广告',
            Type: '设备名称、设备标识符（IMEI AndroidID OAID）、设备激活时间、硬件型号、操作系统版本、应用程序版本、软件识别码、设备及应用设置（如地区/语言/时区/字号）',
            Links: 'https://market.cloud.tencent.com/stores/1304895596',
          },
          {
            ProductName: 'libgdx游戏引擎',
            CompanyName: 'libgdx项目组',
            Uses: '广告游戏基础框架',
            Type: '无',
            Links: 'https://libgdx.com/',
          },
          {
            ProductName: '个数',
            CompanyName: '每日互动股份有限公司',
            Uses: '统计类',
            Type: 'AndroidID、读取ICCID、设备信息-IMEI、设备信息-IMSI、MAC地址 、WIFI的BSSID、WIFI的SSID、系统属性、设备序列号、已安装APP信息、SD卡_创建目录、SD卡_查询文件列表、SD卡_创建文件',
            Links: 'https://docs.getui.com/privacy/?from_wecom=1',
          },
          {
            ProductName: 'Flexbox-layout',
            CompanyName: '暂无公司信息',
            Uses: '界面UI显示',
            Type: '无',
            Links: 'https://github.com/google/flexbox-layout',
          },
          {
            ProductName: 'GdtAd',
            CompanyName: '暂无公司信息',
            Uses: '广告',
            Type: '当前运行应用进程、设备运营商、已安装APP信息、传感器信息、WiFi路由器MAC地址、设备的MAC地址、wifi state',
            Links: 'https://developers.adnet.qq.com/doc/android/access_doc',
          },
          {
            ProductName: 'PahoMqtt',
            CompanyName: '暂无公司信息',
            Uses: '消息中心长链接',
            Type: 'SD卡_创建目录、 SD卡_创建目录、SD卡_查询文件列表、SD卡_删除文件',
            Links: 'https://github.com/eclipse/paho.mqtt.android',
          },
          {
            ProductName: 'Glide',
            CompanyName: '暂无公司信息',
            Uses: '图片加载器',
            Type: '无',
            Links: 'https://github.com/bumptech/glide',
          },
          {
            ProductName: 'ijk视频播放器',
            CompanyName: '暂无公司信息',
            Uses: '视频播放器',
            Type: '无',
            Links: 'https://github.com/bilibili/ijkplayer',
          },
          {
            ProductName: 'Yalantis',
            CompanyName: '暂无公司信息',
            Uses: '音视频上传',
            Type: '无',
            Links: 'https://github.com/Yalantis/Side-Menu.Android',
          },
          {
            ProductName: 'SmartTabLayout',
            CompanyName: '暂无公司信息',
            Uses: '界面UI显示',
            Type: '无',
            Links: 'https://github.com/ogaclejapan/SmartTabLayout',
          },
          {
            ProductName: 'ReLinker',
            CompanyName: '暂无公司信息',
            Uses: 'so动态加载',
            Type: '无',
            Links: 'https://github.com/KeepSafe/ReLinker',
          },
          {
            ProductName: 'ShortcutBadger',
            CompanyName: '暂无公司信息',
            Uses: '桌面角标显示',
            Type: '已安装APP信息',
            Links: 'https://github.com/leolin310148/ShortcutBadger',
          },
          {
            ProductName: 'AirbnbLottie',
            CompanyName: '暂无公司信息',
            Uses: '图片动画显示',
            Type: '无',
            Links: 'https://github.com/airbnb/lottie-android',
          },
          {
            ProductName: '百度移动广告SDK',
            CompanyName: '北京百度网讯科技有限公司',
            Uses: '广告类',
            Type: '当前运行应用进程、已安装APP信息、读取设备IP、设备运营商、已安装APP信息、系统属性、监视多媒体文件、SD卡_创建目录、SD卡_查询文件列表、SD卡_创建文件、设备标识符（IMEI、IDFA、Android ID、IMSI、OAID 、MAC 地址）、传感器信息、WiFi路由器MAC地址、重力传感器',
            Links: 'https://app.baidu.com/newapp/index/',
          },
          {
            ProductName: '个推鲸智运营',
            CompanyName: '每日互动股份有限公司',
            Uses: '用户智能运营',
            Type: '无',
            Links: 'https://www.getui.com/intelligentoperation',
          },
          {
            ProductName: 'zaaach CityPicker',
            CompanyName: '无',
            Uses: '基础功能类',
            Type: '无',
            Links: 'https://github.com/zaaach/CityPicker',
          },
          {
            ProductName: 'FaceBook soloder',
            CompanyName: 'facebook',
            Uses: '基础功能类',
            Type: '无',
            Links: 'https://github.com/facebook/SoLoader',
          },
          {
            ProductName: 'Bolts',
            CompanyName: '无',
            Uses: '后台异步任务管理库',
            Type: '无',
            Links: 'https://github.com/Jadyli/Bolts-Android/blob/master/README_CN.md',
          },
          {
            ProductName: '炬焰SDK（小视频图文SDK）',
            CompanyName: '湖北今日头条科技有限公司',
            Uses: '短剧播放',
            Type: '设备信息（设备厂商、设备型号、设备内存、操作系统版本、IMSI、IMEI AndroidID 、OAID）、SD卡_创建目录、SD卡_查询文件列表、SD卡_创建文件、设备标识符（IMEI、IDFA、Android ID、IMSI、OAID 、MAC 地址）传感器信息、WiFi路由器MAC地址、获取IP地址、获取正在运行的进程、重力传感器',
            Links: 'https://www.csjplatform.com/supportcenter/5824',
          },
          {
            ProductName: '阿里妈妈广告',
            CompanyName: '杭州阿里妈妈软件服务有限公司',
            Uses: '商业化广告合作',
            Type: '设备信息（设备厂商、设备型号、设备内存、操作系统版本、IMSI 、IMEI AndroidID、OAID）、SD卡_创建目录、SD卡_查询文件列表、SD卡_创建文件、SD卡_删除文件、设备标识符（IMEI、IDFA、Android ID、IMSI、OAID 、MAC 地址）传感器信息、WiFi路由器MAC地址、获取IP地址、获取正在运行的进程',
            Links:
              'https://terms.alicdn.com/legal-agreement/terms/suit_bu1_ali_mama_division/suit_bu1_ali_mama_division202202171548_47251.html',
          },
        ],
        option: {
          column: [
            {
              label: '产品名称',
              prop: 'ProductName',
            },
            {
              label: '企业名称',
              prop: 'CompanyName',
            },
            {
              label: '用途/使用场景',
              prop: 'Uses',
            },
            {
              label: '收集的信息类型',
              prop: 'Type',
            },
            {
              label: '第三方个人信息保护政策链接或官网链接',
              prop: 'Links',
            },
          ],
        },
        tableData1: [
          {
            Receive: '杭州小满信息科技有限公司',
            Contact: 'guyu@hixiaoman.com',
            Treatment: '收集、存储、使用',
            Uses: 'SaaS营销工具（内容）',
            Type: '用户ID',
          },
          {
            Receive: '腾讯音乐娱乐（深圳）有限公司',
            Contact: '+86 (755) 8601-3388',
            Treatment: '收集、存储、使用',
            Uses: '播放版权音乐',
            Type: '设备信息，用户行为数据',
          },
          {
            Receive: '林州薪云信息科技有限公司',
            Contact: '4008-381-081',
            Treatment: '收集、存储、使用',
            Uses: '现金提现',
            Type: '手机号、实名认证信息（提现到一定金额）、微信open id、支付宝账号',
          },
          {
            Receive: '数美天下（北京）科技有限公司',
            Contact: '400-850-0030',
            Treatment: '委托处理',
            Uses: '落实合规要求，监测识别算法输入和输出数据中的违法违规和不良信息（内容审核）',
            Type: '用户内容数据（即使用生成合成算法/AI功能的输入数据、输出数据）',
          },
          {
            Receive: '北京火山引擎科技有限公司',
            Contact: '400-850-0030',
            Treatment: '收集、存储、使用',
            Uses: '音视频字幕识别',
            Type: '用户内容数据',
          },
          {
            Receive: '深圳市和讯华谷信息技术有限公司',
            Contact: 'support@jiguang.cn',
            Treatment: '收集、存储、使用',
            Uses: '商业化广告合作',
            Type: '用户广告行为数据、应用安装列表、设备信息',
          },
          {
            Receive: '科大讯飞股份有限公司',
            Contact: '0551-65331511',
            Treatment: '收集、存储、使用',
            Uses: '彩铃合作',
            Type: '用户广告行为数据、应用安装列表、设备信息',
          },
          {
            Receive: '深圳前海新之江信息技术有限公司',
            Contact: 'privacy@pinduoduo.com',
            Treatment: '收集、存储、使用',
            Uses: '商业化广告合作',
            Type: '用户广告行为数据、应用安装列表、设备信息',
          },
          {
            Receive: '上海美数广告有限公司',
            Contact: '010-85907587',
            Treatment: '收集、存储、使用',
            Uses: '商业化广告合作',
            Type: '用户广告行为数据、应用安装列表、设备信息',
          },
          {
            Receive: '上海佳投互联网技术集团有限公司',
            Contact: 'adx@jiaads.com',
            Treatment: '收集、存储、使用',
            Uses: '商业化广告合作',
            Type: '用户广告行为数据、应用安装列表、设备信息',
          },
          {
            Receive: '天津快友世纪科技有限公司',
            Contact: 'service@adview.com',
            Treatment: '收集、存储、使用',
            Uses: '商业化广告合作',
            Type: '用户广告行为数据、应用安装列表、设备信息',
          },
          {
            Receive: '广州幂动科技有限公司',
            Contact: 'bussiness@midongtech.cn',
            Treatment: '收集、存储、使用',
            Uses: '商业化广告合作',
            Type: '用户广告行为数据、应用安装列表、设备信息',
          },
          {
            Receive: '北京美数信息科技有限公司',
            Contact: '010-59007186',
            Treatment: '收集、存储、使用',
            Uses: '商业化广告合作',
            Type: '用户广告行为数据、应用安装列表、设备信息',
          },
          {
            Receive: '网易（杭州）网络有限公司',
            Contact: '95163223',
            Treatment: '委托处理',
            Uses: '落实合规要求，监测识别算法输入和输出数据中的违法违规和不良信息（内容审核）',
            Type: '用户内容数据（即使用生成合成算法/AI功能的输入数据、输出数据）',
          },
          {
            Receive: '北京时域科技有限公司',
            Contact: 'ace@timedomain.ai',
            Treatment: '委托处理',
            Uses: '合成演唱歌曲',
            Type: '生成合成算法/AI功能生成的旋律、歌词数据（不含用户输入数据）',
          },
        ],
        option1: {
          column: [
            {
              label: '数据接收方',
              prop: 'Receive',
            },
            {
              label: '联系方式',
              prop: 'Contact',
            },
            {
              label: '处理方式',
              prop: 'Treatment',
            },
            {
              label: '用途',
              prop: 'Uses',
            },
            {
              label: '共享个人信息类型',
              prop: 'Type',
            },
          ],
        },
        appName: '',
        appNameMap: new Map([
          ['kuaiyin', { appName: '快音', moreOpts: false }],
          ['kuaiyintingge', { appName: '快音听歌版', moreOpts: false }],
          ['kuaiyinjisuban', { appName: '快音极速版', moreOpts: false }],
          ['kuaiyinyue', { appName: '快音悦', moreOpts: false, moreMoreOpts: true }],
          ['kuaiyinfm', { appName: '快音FM', moreOpts: true }],
        ]),
      };
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
      let app_name = 'kuaiyin';
      if (this.$route.query.app_name) {
        app_name = this.$route.query.app_name;
      } else if (window.bridge && window.bridge.getAppName) {
        app_name = window.bridge.getAppName() || 'kuaiyin';
      }
      const needMoreOpts = this.appNameMap.get(app_name).moreOpts;
      const needMoreMoreOpts = this.appNameMap.get(app_name).moreMoreOpts;
      const newOpts = [
        {
          ProductName: '开心音符基础库',
          CompanyName: '成都开心音符科技有限公司',
          Uses: 'app 性能追踪',
          Type: '设备信息-IMEI、设备信息-MAC、Android ID、OpenUDID、GUID、IP、DEVICEID、用户ID',
          Links: 'https://www.kaixinyf.cn/',
        },
        {
          ProductName: '开心音符性能检测工具',
          CompanyName: '成都开心音符科技有限公司',
          Uses: 'app性能监控',
          Type: '设备信息-IMEI、设备信息-MAC、Android ID、OpenUDID、GUID、IP、DEVICEID、用户ID',
          Links: 'https://www.kaixinyf.cn/',
        },
      ];
      const newMoreOpts = [
        {
          ProductName: '开心音符基础库',
          CompanyName: '成都开心音符科技有限公司',
          Uses: 'app 性能追踪',
          Type: '设备信息-IMEI、设备信息-MAC、Android ID、OpenUDID、GUID、IP、DEVICEID、用户ID',
          Links: 'https://www.kaixinyf.cn/',
        },
        {
          ProductName: '开心音符性能检测工具',
          CompanyName: '成都开心音符科技有限公司',
          Uses: 'app性能监控',
          Type: '设备信息-IMEI、设备信息-MAC、Android ID、OpenUDID、GUID、IP、DEVICEID、用户ID',
          Links: 'https://www.kaixinyf.cn/',
        },
        {
          ProductName: 'AirbnbLottie',
          CompanyName: 'airbnb',
          Uses: '动态图片加载',
          Type: '动态图片',
          Links: 'https://airbnb.design/lottie/',
        },
        {
          ProductName: 'ApacheHttp',
          CompanyName: 'Apache',
          Uses: '网络请求',
          Type: '网络请求',
          Links: 'https://httpd.apache.org/',
        },
        {
          ProductName: 'BouncyCastle',
          CompanyName: 'BouncyCastle',
          Uses: '网络数据加密',
          Type: '网络数据',
          Links: 'https://www.bouncycastle.org/',
        },
        {
          ProductName: 'efs',
          CompanyName: '友盟',
          Uses: '性能/崩溃检测',
          Type: '手机性能',
          Links: 'https://www.umeng.com/',
        },
        {
          ProductName: 'ExoPlayer',
          CompanyName: 'google',
          Uses: '音乐播放',
          Type: '音乐内容',
          Links: 'https://github.com/google/ExoPlayer',
        },
        {
          ProductName: 'Flexbox-layout',
          CompanyName: 'goole',
          Uses: '界面布局',
          Type: '界面布局',
          Links: 'https://github.com/google/flexbox-layout',
        },
        {
          ProductName: 'Glide',
          CompanyName: 'Bump Technologies',
          Uses: '图片加载',
          Type: '图片',
          Links: 'https://github.com/bumptech/glide',
        },
        {
          ProductName: 'ijk视频播放器',
          CompanyName: 'bilibili',
          Uses: '音乐播放',
          Type: '播放的音乐内容',
          Links: 'https://github.com/bilibili/ijkplayer',
        },
        {
          ProductName: 'InMobi移动广告平台',
          CompanyName: 'InMobi',
          Uses: '广告',
          Type: '设备标识符（ IMSI、IMEI、MAC地址、AndroidID、SSID、BSSID、OAID、UDID）',
          Links: 'https://www.inmobi.com/',
        },
        {
          ProductName: 'LiulishuoFDownloader',
          CompanyName: '流利说',
          Uses: '文件下载',
          Type: '文件下载',
          Links: 'https://github.com/lingochamp/FileDownloader',
        },
        {
          ProductName: 'Okhttp3',
          CompanyName: 'square',
          Uses: '网络请求',
          Type: '网络请求',
          Links: 'https://github.com/square/okhttp',
        },
        {
          ProductName: 'Okio',
          CompanyName: 'square',
          Uses: '网络数据二进制序列化为对象',
          Type: '网络数据',
          Links: 'https://github.com/square/okio',
        },
        {
          ProductName: 'PahoMqtt',
          CompanyName: 'Eclipse',
          Uses: '消息中心的消息到达通知',
          Type: '消息到达通知',
          Links: 'https://github.com/eclipse/paho.mqtt.android',
        },
        {
          ProductName: 'PhotoView',
          CompanyName: 'Baseflow',
          Uses: '图片缩放',
          Type: '图片',
          Links: 'https://github.com/Baseflow/PhotoView',
        },
        {
          ProductName: 'Sanselan',
          CompanyName: 'Apache',
          Uses: '读取图片信息',
          Type: '图片',
          Links: 'https://github.com/apache/sanselan',
        },
        {
          ProductName: 'ShortcutBadger',
          CompanyName: 'ShortcutBadger',
          Uses: '桌面未读数角标',
          Type: '运行中进程',
          Links: 'https://github.com/leolin310148/ShortcutBadger',
        },
        {
          ProductName: 'SmartTabLayout',
          CompanyName: 'ogaclejapan',
          Uses: '界面布局',
          Type: '界面布局',
          Links: 'https://github.com/ogaclejapan/SmartTabLayout',
        },
        {
          ProductName: 'TopOn聚合平台',
          CompanyName: 'TopOn',
          Uses: '广告',
          Type: '设备标识符（IMEI、IDFA、Android ID、IMSI、OAID 、MAC 地址）',
          Links: 'https://www.toponad.com/zh-cn',
        },
        {
          ProductName: 'UC啄木鸟',
          CompanyName: '友盟',
          Uses: '崩溃检测',
          Type: '设备信息-IMEI、设备信息-MAC、Android ID、OpenUDID、GUID、IP、DEVICEID',
          Links: 'https://www.umeng.com/',
        },
        {
          ProductName: 'Yalantis',
          CompanyName: 'Yalantis',
          Uses: '图片裁剪',
          Type: '图片',
          Links: 'https://github.com/Yalantis/uCrop',
        },
        {
          ProductName: '汇世Mbridge',
          CompanyName: '汇世',
          Uses: '广告',
          Type: '设备信息（设备厂商 设备型号 设备内存 操作系统版本 IMSI IMEI AndroidID OAID）',
          Links: 'https://www.mintegral.com/cn/',
        },
        {
          ProductName: '飞书点播',
          CompanyName: '头条',
          Uses: '视频播放',
          Type: '设备名称、设备标识符（IMEI AndroidID OAID）、设备激活时间、硬件型号、操作系统版本、应用程序版本、软件识别码、设备及应用设置（如地区/语言/时区/字号）',
          Links: 'https://www.volcengine.com/',
        },
      ];

      if (this.$route.query.app_name) {
        app_name = this.$route.query.app_name;
      } else if (window.bridge && window.bridge.getAppName) {
        app_name = window.bridge.getAppName() || 'kuaiyin';
      }

      this.appName = this.appNameMap.get(app_name).appName;
      document.title = `${this.appName}App涉及收集用户信息的第三方SDK或类似应用程序目录`;
      console.log(`needMoreMoreOpts`, needMoreMoreOpts);
      if (needMoreOpts) {
        this.tableData = this.tableData.concat(newOpts);
      } else if (needMoreMoreOpts) {
        this.tableData = this.tableData.concat(newMoreOpts);
      }
    },
    methods: {},
  };
</script>

<style scoped>
  body {
    font: 14px/150% 'Helvetica Neue', Tahoma, Arial, PingFangSC-Regular, 'Hiragino Sans GB', 'Microsoft Yahei',
      sans-serif;
    margin: 0;
    padding: 0;
    width: 100%;
    background-color: #fff;
    /*padding-left: constant(safe-area-inset-left);*/
    /*padding-left: env(safe-area-inset-left);*/
    /*padding-right: constant(safe-area-inset-right);*/
    /*padding-right: env(safe-area-inset-right);*/
    /*padding-bottom: constant(safe-area-inset-bottom);*/
    /*padding-bottom: env(safe-area-inset-bottom);*/
  }

  article {
    margin: 0 4%;
  }
  h3 {
    margin: 15px 0;
    padding-top: 0;
    padding-bottom: 0;
    color: #3c3c3c;
    font-size: 15px;
  }
  h5 {
    margin: 15px 0;
    font-size: 14px;
  }
  p {
    margin: 12px 0;
    font-size: 13px;
    text-indent: 2em;
  }
  div.bold {
    margin: 12px 0;
    font-size: 13px;
  }
  .center {
    text-align: center;
  }
  ul > li {
    margin: 5px 0;
    font-size: 13px;
    list-style: none;
  }
  ul > li > span {
    display: inline-block;
    margin: 4px 0;
  }
  .align-start {
    width: 100%;
    overflow-x: scroll;
    text-align: start;
  }
  .align-start a {
    color: #007aff;
  }
  .bold {
    font-weight: bold;
  }
  ::-webkit-scrollbar {
    display: none;
  }
</style>
